import { Injectable } from '@angular/core';
import {
  AssetItem,
  AssetType,
  CloudinarySignableForm,
  FolderGroupType,
  GetCloudinarySignatureGQL,
  ResourceType,
  SaveMediaFromCloudGQL,
  SaveMediaInput,
} from '@designage/gql';
import { environment } from '@desquare/environments';
import { ICloudinaryUploadResponse } from '@desquare/interfaces';
import { lastValueFrom } from 'rxjs';
import { CurrentUserService } from '../current-user/current-user.service';
import { ICloudinary } from './interface/ICloudinary';
import { getOptimizedUrl, urlToWebp } from '@desquare/utils';
import { ResizeCropMethodURIs, ZoneResolutions } from '@desquare/enums';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const cloudinary: any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let widget: any = undefined;

interface ISignableUploadForm {
  folder: string;
  source: string; // 'uw'
  timestamp: number;
  upload_preset: string;
  api_key?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CloudinaryService {
  constructor(
    private currentUserService: CurrentUserService,
    private getSignatureGql: GetCloudinarySignatureGQL,
    private saveMediaGQL: SaveMediaFromCloudGQL
  ) {}

  getUploadWidget() {
    return widget;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createUploadWidget(
    cloudinaryArgs: ICloudinary,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn: (_error: any, _result: any) => void
  ) {
    const { cloudName, uploadWidgetParameters } = cloudinaryArgs;
    const {
      preset,
      sources,
      folder,
      multiple,
      styles,
      clientAllowedFormats,
      language,
      text,
      apiKey,
      autoMinimize,
    } = uploadWidgetParameters;

    if (!widget || widget.isDestroyed()) {
      widget = cloudinary.createUploadWidget(
        {
          cloudName,
          upload_preset: preset,
          sources,
          folder,
          multiple,
          styles,
          clientAllowedFormats,
          language,
          text,
          apiKey,
          autoMinimize,
          uploadSignature: this.generateDynamicSignature,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any, result: any) => {
          fn(error, result);
        }
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateDynamicSignature = (callback: any, params_to_sign: any) => {
    /*
    {
      "timestamp": 1683206073,
      "folder": "USER/undefined",
      "upload_preset": "ml_default",
      "source": "uw"
    }
    */

    this.getSignature(params_to_sign).then((signature) => {
      // set signature inside widget
      callback(signature);
    });
  };

  destroyUploadWidget() {
    widget.destroy({ removeThumbnails: true }).then(() => {
      // console.log('Widget was destroyed');
    });
  }

  getDefaultCloudinaryParams() {
    const folder = this.currentUserService.currentProfile?.id
      ? `${FolderGroupType.Profile}/${this.currentUserService.currentProfile?.id}`
      : `${FolderGroupType.User}/${this.currentUserService.currentUser!.id}`;
    const [folderGroupType, idFolder] = folder.split('/');
    const cloudinaryParams = environment.cloudinary;
    cloudinaryParams.uploadWidgetParameters.folder = folder;

    return cloudinaryParams;
  }

  async directUpload(
    fileType: 'raw' | 'image' | 'video',
    fileContent: Blob,
    overwrite_public_id?: string,
    cloudinaryArgs?: ICloudinary,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (_error: any, _result: ICloudinaryUploadResponse) => void
  ) {
    if (!cloudinaryArgs) {
      cloudinaryArgs = this.getDefaultCloudinaryParams();
    }

    // overwrite_public_id
    // 'PROFILE/42c0e5b8-86ba-49be-a843-55ac8c4edc86/b6gmie63jqbogmyldaim';
    const public_id = overwrite_public_id
      ? this.getPublicId(overwrite_public_id)
      : undefined;
    const { cloudName, uploadWidgetParameters } = cloudinaryArgs;
    const { preset, folder, apiKey } = uploadWidgetParameters;

    const timestamp = Math.round(new Date().getTime() / 1000);
    const signableForm: CloudinarySignableForm = {
      folder,
      source: 'uw',
      timestamp,
      upload_preset: '', //preset,
    };
    if (overwrite_public_id) {
      // optional
      signableForm.public_id = public_id;
      signableForm.overwrite = true;
      signableForm.invalidate = true;
    }

    const signature = await this.getSignature(signableForm);
    const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudName}/${fileType}/upload`;
    const xhrPostFile = new XMLHttpRequest();

    xhrPostFile.open('POST', uploadUrl);
    xhrPostFile.onload = async () => {
      const response: ICloudinaryUploadResponse = JSON.parse(
        xhrPostFile.response
      );

      if (fn) {
        fn(null, response);
      }
    };

    // fire upload
    const formData = new FormData();

    formData.append('folder', signableForm.folder);
    formData.append('source', signableForm.source);
    formData.append('timestamp', `${signableForm.timestamp}`);
    formData.append('upload_preset', signableForm.upload_preset);
    if (signableForm.public_id) {
      formData.append('public_id', signableForm.public_id);
      formData.append('overwrite', `${signableForm.overwrite}`);
      formData.append('invalidate', `${signableForm.overwrite}`);
    }

    formData.append(
      'api_key',
      cloudinaryArgs?.uploadWidgetParameters.apiKey || ''
    );

    formData.append('signature', signature);
    // formData.append(
    //   'public_id',
    //   'PROFILE/42c0e5b8-86ba-49be-a843-55ac8c4edc86/nonsumv0wbkyzbxzhbi1'
    // );
    formData.append('file', fileContent);
    xhrPostFile.send(formData);
  }

  async directUploadPromise(
    fileContent: Blob,
    overwrite_public_id?: string,
    cloudinaryArgs?: ICloudinary
  ): Promise<ICloudinaryUploadResponse> {
    return new Promise(async (resolve, reject) => {
      if (!cloudinaryArgs) {
        cloudinaryArgs = this.getDefaultCloudinaryParams();
      }

      // overwrite_public_id
      // 'PROFILE/42c0e5b8-86ba-49be-a843-55ac8c4edc86/b6gmie63jqbogmyldaim';
      const public_id = overwrite_public_id
        ? this.getPublicId(overwrite_public_id)
        : undefined;
      const { cloudName, uploadWidgetParameters } = cloudinaryArgs;
      const { preset, folder, apiKey } = uploadWidgetParameters;

      const timestamp = Math.round(new Date().getTime() / 1000);
      const signableForm: CloudinarySignableForm = {
        folder,
        source: 'uw',
        timestamp,
        upload_preset: '', //preset,
      };
      if (overwrite_public_id) {
        // optional
        signableForm.public_id = public_id;
        signableForm.overwrite = true;
        signableForm.invalidate = true;
      }

      let fileType = fileContent.type.split('/')[0].toLocaleLowerCase();
      console.log('fileType before: ', fileType);
      if (!['image', 'video'].includes(fileType)) {
        fileType = 'raw';
      }
      console.log('fileType after: ', fileType);

      const signature = await this.getSignature(signableForm);
      const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudName}/${fileType}/upload`;
      const xhrPostFile = new XMLHttpRequest();

      xhrPostFile.open('POST', uploadUrl);
      xhrPostFile.onload = async () => {
        const response: ICloudinaryUploadResponse = JSON.parse(
          xhrPostFile.response
        );

        resolve(response);
      };

      xhrPostFile.onerror = async () => {
        reject({
          status: xhrPostFile.status,
          statusText: xhrPostFile.statusText,
        });
      };

      // fire upload
      const formData = new FormData();

      formData.append('folder', signableForm.folder);
      formData.append('source', signableForm.source);
      formData.append('timestamp', `${signableForm.timestamp}`);
      formData.append('upload_preset', signableForm.upload_preset);
      if (signableForm.public_id) {
        formData.append('public_id', signableForm.public_id);
        formData.append('overwrite', `${signableForm.overwrite}`);
        formData.append('invalidate', `${signableForm.overwrite}`);
      }

      formData.append(
        'api_key',
        cloudinaryArgs?.uploadWidgetParameters.apiKey || ''
      );

      formData.append('signature', signature);
      // formData.append(
      //   'public_id',
      //   'PROFILE/42c0e5b8-86ba-49be-a843-55ac8c4edc86/nonsumv0wbkyzbxzhbi1'
      // );
      formData.append('file', fileContent);
      xhrPostFile.send(formData);
    });
  }

  /**
   * splits a cloudinary public_id into folder and id
   * @param id
   * @returns
   */
  splitFolderPublicId(id: string) {
    const publicId = this.getPublicId(id);
    const folder = id.replace(`/${publicId}`, '');
    return {
      publicId,
      folder,
    };
  }

  getPublicId(complete_public_id: string) {
    const parts = complete_public_id.split('/');
    return parts[parts.length - 1];
  }

  async getSignature(signableForm: ISignableUploadForm) {
    const { data } = await lastValueFrom(
      this.getSignatureGql.fetch({ uploadForm: signableForm })
    );
    return data.mediaCloudinarySignature?.signature || '';
  }

  async directUploadImage_OLD(
    image: Blob,
    ceSceneSource: string,

    cloudinaryArgs?: ICloudinary,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (_error: any, _result: any) => void
  ) {
    if (!cloudinaryArgs) {
      cloudinaryArgs = this.getDefaultCloudinaryParams();
    }

    const { cloudName, uploadWidgetParameters } = cloudinaryArgs;
    const {
      preset,
      sources,
      folder,
      multiple,
      styles,
      clientAllowedFormats,
      language,
      text,
      apiKey,
      autoMinimize,
    } = uploadWidgetParameters;

    const xhrGetSign = new XMLHttpRequest();
    const signatureUrl = new URL(
      `${environment.urls.designageApiEndpoint}/signRequest`
    );

    signatureUrl.searchParams.set('folder', folder);
    signatureUrl.searchParams.append('uploadPreset', preset);

    xhrGetSign.open('GET', signatureUrl.toString());
    xhrGetSign.responseType = 'json';
    console.log('gettting signature for cloudinary');
    xhrGetSign.onload = async () => {
      const { uploadSignature, uploadSignatureTimestamp } = xhrGetSign.response;
      console.log('signature:', uploadSignature);
      const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudinaryArgs?.cloudName}/image/upload`;
      const xhrPostFile = new XMLHttpRequest();

      xhrPostFile.open('POST', uploadUrl);
      xhrPostFile.onload = async () => {
        console.log('response from cloudinary:');
        console.log(xhrPostFile.response);
        const response: ICloudinaryUploadResponse = xhrPostFile.response;
        const cloudinaryResponse: SaveMediaInput = {
          publicId: response.public_id,
          folder,
          folderGroupType: FolderGroupType.Profile,
          name: '',
          resourceType: ResourceType.Image,
          secureUrl: response.secure_url,
          url: response.url,
        };
        // await this.saveMediaGQL
        //   .mutate({ input: cloudinaryResponse })
        //   .toPromise();

        if (fn) {
          // fn(error, result)
        }
      };

      // fire upload
      const formData = new FormData();
      formData.append('folder', folder);
      formData.append('source', 'uw');
      formData.append('timestamp', uploadSignatureTimestamp);
      formData.append('upload_preset', preset);

      formData.append(
        'api_key',
        cloudinaryArgs?.uploadWidgetParameters.apiKey || ''
      );
      formData.append('signature', uploadSignature);
      formData.append(
        'public_id',
        'PROFILE/42c0e5b8-86ba-49be-a843-55ac8c4edc86/nonsumv0wbkyzbxzhbi1'
      );
      formData.append('file', image);
      xhrPostFile.send(formData);
    };

    xhrGetSign.send();
  }

  // TODO here: get this code checked if its safe
  async getSceneBlob(url: string): Promise<string> {
    if (url.startsWith('http://')) {
      url = url.replace('http://', 'https://');
    }
    const sceneBlob = await fetch(url).then((result) => result.text());

    // console.log('sceneBlob: ', sceneBlob); // DEBUG

    return sceneBlob;
  }
}
